import { CLEAR_SSPR_ERROR, SSPR_ERROR } from "../actions/hookActions";

const defaultState = {
  error: "",
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SSPR_ERROR:
      // We have several forms of error
      // Message: "Invalid code"
      // or
      // a JSON stringified object with various possible error codes
      let message = {};
      try {
        message = JSON.parse(action.err.Message);
      } catch (e) {
        // Assume it is a string error
        message =
          action.err && action.err.Message
            ? {
                errors: {
                  misc: action.err.Message,
                },
              }
            : {
                errors: {
                  misc: "Please contact service desk",
                },
              };
      }
      return { ...state, error: message };
    case CLEAR_SSPR_ERROR:
      return { ...state, error: "" };
    default:
      return state;
  }
};
