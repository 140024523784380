import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import * as cfg from "./config";

import createRootReducer from "./reducers";
import { authServiceMiddlewareCreator } from "@authzsvc/api-lib";

const history = createBrowserHistory();

const middleware = [
  routerMiddleware(history),
  thunk,
  authServiceMiddlewareCreator(cfg),
];

let composed;
if (window.__REDUX_DEVTOOLS_EXTENSION__ !== undefined) {
  composed = compose(
    applyMiddleware(...middleware),
    window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  composed = applyMiddleware(...middleware);
}

export { history };

export default createStore(createRootReducer(history), {}, composed);
