import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSSPRError } from "../../selectors";
import { Message } from "semantic-ui-react";
import { clearSSPRError } from "../../actions/hookActions";

const SSPRError = () => {
  const dispatch = useDispatch();
  const error = useSelector(getSSPRError);
  const clearError = () => {
    dispatch(clearSSPRError());
  };
  return (
    <>
      {error &&
        error.errors &&
        Object.values(error.errors).map(function (item, i) {
          return (
            <Message key={i} negative onDismiss={() => clearError()}>
              {item}
            </Message>
          );
        })}
    </>
  );
};

export default SSPRError;
