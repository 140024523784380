import React, { Component } from "react";
import {
  Button,
  Container,
  Header,
  Icon,
  Message,
  Segment,
  Image,
} from "semantic-ui-react";
import {
  keycloakClientId,
  keycloakGuestRealm,
  keycloakUrl,
  sidebar_identities,
  keycloakRedirectBase,
} from "../../config";
import Keycloak from "keycloak-js";
import { connect } from "react-redux";
import SSO from "../../assets/images/sso-guest-login-indication.png";

const silentSsoCheckUrl = `${window.location.origin}/check-sso-silent.html`;

class GuestAccountRegistration extends Component {
  state = {
    guestClient: new Keycloak({
      url: keycloakUrl,
      realm: keycloakGuestRealm,
      clientId: keycloakClientId,
    }),
  };

  render() {
    // Initialise the Keycloak adapter without logging in to later generate a
    // URL for guest registration
    this.state.guestClient.init({
      promiseType: "native",
      onLoad: "check-sso",
      silentCheckSsoRedirectUri: silentSsoCheckUrl,
      silentCheckSsoFallback: false,
      flow: "implicit",
    });
    // Redirect to a page in the Users Portal that requires login using idp Hint = guest
    let registerUrl = this.state.guestClient.createRegisterUrl({
      redirectUri: `${keycloakRedirectBase}/guest-registration/complete`,
    });
    return (
      <Segment basic>
        <Container>
          <Segment>
            <Header as={"h2"}>
              <Icon name="user outline" /> Guest Account Registration
            </Header>
            <Message>
              <p>
                You can create a Guest account to access CERN by providing your
                name, email and setting a password.
                <br />
                When you{" "}
                <a href={sidebar_identities}>
                  <b> authenticate to CERN </b>
                </a>
                , select the <b>"External email - Guest access" </b>
                option and enter your email and the password you chose.
                <br />
                <br />
                <Image src={SSO} size="big" centered />
              </p>
              <p>
                You will be able to{" "}
                <a
                  href={
                    keycloakUrl +
                    "/realms/guest/login-actions/reset-credentials"
                  }
                >
                  <b>reset your password</b>
                </a>{" "}
                on the login page when authenticating to any CERN service. If
                you encounter any issues with your account,{" "}
                <b>please contact the </b>
                <a href="https://cern.service-now.com/service-portal">
                  <b>Service desk</b>
                </a>
                <br />
                <br />
                Ready? Select <b> "Create Guest Account" </b> to create your
                Guest account.
              </p>
            </Message>
            <Button
              icon
              primary
              type="submit"
              labelPosition="left"
              href={registerUrl}
            >
              <Icon name="add user" />
              Create Guest Account
            </Button>
          </Segment>
        </Container>
      </Segment>
    );
  }
}

export default connect((state) => ({}), {})(GuestAccountRegistration);
