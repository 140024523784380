import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { Icon, Segment, Header, Grid, Button } from "semantic-ui-react";
import {
  isApiCallLoading,
  selectAccountManagementOn,
  userTokenHasRole,
} from "../../selectors";
import { withApiCapabilities } from "../../wrappers";
import ChangePasswordModal from "../accountManagement/passwordManagement/ChangePasswordModal";
import { errorAction, types as at } from "@authzsvc/api-lib";
import { Tooltip } from "@authzsvc/common-react-components";
import { isSecondaryOrService } from "../../utils";
import {
  changePassword,
  changeEdhPassword,
  resetPassword,
  setInitialPassword,
} from "../../actions/api";
import { notification } from "antd";

const IdentityEditActions = ({
  identity,
  history,
  primaryAccount,
  cernAccount,
  loadingPasswords,
  changePassword,
  changeEdhPassword,
  authenticatedUserHasCERNLoA,
}) => {
  const onChangePassword = async (oldPassword, newPassword) => {
    const changeResp = await changePassword(
      primaryAccount.id,
      oldPassword,
      newPassword
    );
    if (changeResp.type === errorAction(at.CHANGE_PASSWORD)) {
      notification["error"]({
        message: changeResp.payload.title,
        description: changeResp.payload.data.message,
      });
    }
    return changeResp;
  };

  const onChangeEdhPassword = async (oldPassword, newPassword) => {
    const changeEdhResp = await changeEdhPassword(
      primaryAccount.id,
      oldPassword,
      newPassword
    );
    if (changeEdhResp.type === errorAction(at.CHANGE_EDHPASSWORD)) {
      notification["error"]({
        message: changeEdhResp.payload.title,
        description: changeEdhResp.payload.data.message,
      });
    }
    return changeEdhResp;
  };

  const goToPrimaryAccount = () => {
    history.push(`/accounts/${primaryAccount.id}`);
  };

  return (
    <>
      <Segment basic>
        <Header as="h3">
          <span>
            <Icon name="setting" />
            Actions
          </span>
        </Header>
        <Grid columns={1}>
          {cernAccount ? (
            <>
              <Grid.Row>
                <Button
                  onClick={goToPrimaryAccount}
                  icon
                  labelPosition="left"
                  primary
                  fluid
                  style={{ marginTop: 0 }}
                >
                  <Icon name="lock" />
                  Configure 2FA
                </Button>
              </Grid.Row>
              <Grid.Row>
                <ChangePasswordModal
                  loading={loadingPasswords}
                  onSubmit={(oldPassword, newPassword) =>
                    onChangePassword(oldPassword, newPassword)
                  }
                  identity={identity}
                  title={"Change password"}
                  disabledOptions={{
                    passwordChangeDisabled: !authenticatedUserHasCERNLoA,
                    message:
                      "You need to sign with your CERN account to change your password",
                  }}
                />
              </Grid.Row>
              {!isSecondaryOrService(identity) && (
                <>
                  <Grid.Row>
                    <ChangePasswordModal
                      loading={loadingPasswords}
                      onSubmit={(oldPassword, newPassword) =>
                        onChangeEdhPassword(oldPassword, newPassword)
                      }
                      identity={identity}
                      title={"Change EDH password"}
                      disabledOptions={{
                        passwordChangeDisabled: !authenticatedUserHasCERNLoA,
                        message:
                          "You need to sign with your CERN account to change your password",
                      }}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <Tooltip
                      title="Create a Service or Secondary account (this will open a link to the old account website)"
                      position={"left center"}
                    >
                      <a
                        className="ui icon primary left fluid labeled button"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://account.cern.ch/account/Management/NewAccount.aspx"
                        style={{ marginTop: 0 }}
                      >
                        <i aria-hidden="true" className="external icon" />
                        Create new account
                      </a>
                    </Tooltip>
                  </Grid.Row>
                </>
              )}
              <Grid.Row>
                <Tooltip
                  title="Edit your email or add new mail aliases (this will open a link to the old account website)"
                  position={"left center"}
                >
                  <a
                    className="ui icon primary fluid left labeled button"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://account.cern.ch/account/Management/MyAccounts.aspx"
                    style={{ marginTop: 0 }}
                  >
                    <i aria-hidden="true" className="external icon" />
                    Edit mail settings
                  </a>
                </Tooltip>
              </Grid.Row>
            </>
          ) : (
            <>
              <Grid.Row>
                <a
                  className="ui icon primary fluid left labeled button"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://cern.service-now.com/service-portal?id=index"
                >
                  <i aria-hidden="true" className="external icon" />
                  Request Help
                </a>
              </Grid.Row>
              <Grid.Row>
                <a
                  className="ui icon primary fluid left labeled button"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://cern.service-now.com/service-portal?id=kb_article&n=KB0007539"
                >
                  <i aria-hidden="true" className="external icon" />
                  Reset Password
                </a>
              </Grid.Row>
            </>
          )}
        </Grid>
      </Segment>
    </>
  );
};

IdentityEditActions.propTypes = {
  identity: PropTypes.object.isRequired,
  accountManagementOn: PropTypes.bool.isRequired,
};

export default withApiCapabilities(
  connect(
    (state, ownProps) => ({
      accountManagementOn: selectAccountManagementOn(ownProps.capabilities),
      loadingPasswords:
        isApiCallLoading(state, at.CHANGE_PASSWORD) ||
        isApiCallLoading(state, at.CHANGE_EDHPASSWORD),
      authenticatedUserHasCERNLoA: userTokenHasRole(state, "cern_account"),
    }),
    {
      changePassword,
      changeEdhPassword,
      resetPassword,
      setInitialPassword,
    }
  )(IdentityEditActions)
);
